import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-where-page',
  templateUrl: './where-page.component.html',
  styleUrls: ['./where-page.component.scss']
})
export class WherePageComponent implements OnInit, AfterViewInit {
  @ViewChild('mapContainer') gmap: ElementRef;

  map: google.maps.Map;
  lat = 45.551798;
  lng = 9.075016;

  coordinates = new google.maps.LatLng(this.lat, this.lng);

  mapOptions: google.maps.MapOptions = {
    center: this.coordinates,
    zoom: 19,
  };
  marker = new google.maps.Marker({
    position: this.coordinates,
    map: this.map,
  });

  constructor() { }
  ngOnInit() {
  }

  ngAfterViewInit() {
    this.mapInitializer();
  }

  mapInitializer() {
    this.map = new google.maps.Map(this.gmap.nativeElement,
      this.mapOptions);
    this.marker.setMap(this.map);
  }
}